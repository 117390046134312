import "core-js/stable";
import "regenerator-runtime/runtime";
import { createApp } from "vue";
import axios from "axios";
// Connector Contact Widget
const connector = async () => {
    // Create our vue instance
    const connectorVM = createApp({
        compilerOptions: {
            delimiters: ["${", "}"],
        },
        components: {},
        data: ()=>({
            preference: "",
            step: 1,
            totalSteps: 3,
            honeypot: {
                name: "",
                hash: "",
            },
        }),
        methods: {
            init: function () {
                console.log("Connector.Init()");
            },

            onBack: function () {
                this.step = 1;
            },

            configForm() {
                console.log("Connector.Config()");
                let _s = this;
                let hpn = this.$refs["hp-name"].getAttribute("data-hpname");
                let hph = this.$refs["hp-hash"].getAttribute("data-hphash");
                let form = document.getElementById("connector-form");
                let handle = document.getElementById(hpn);

                this.honeypot.name = hpn;
                this.honeypot.hash = hph;

                console.log("Honeypot Name : " + hpn);
                console.log("Honeypot Hash : " + hph);

                handle.setAttribute("value", hph);
                handle.setAttribute("name", hpn);
                handle.setAttribute("id", hpn);

                form.addEventListener("submit", (event) => {
                    event.preventDefault();

                    var f = event.target;
                    var a = f.elements["action"].getAttribute("value");
                    var d = new FormData(form);
                    var c = {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            "Cache-Control": "no-cache",
                            "X-Requested-With": "XMLHttpRequest",
                            HTTP_X_REQUESTED_WITH: "XMLHttpRequest",
                        },
                    };

                    _s.lockSubmit(f);

                    // if safari and no file selected, delete it
                    if (navigator.userAgent.indexOf("Safari") > -1) {
                        for (var i = 0; i < f.elements.length; i++) {
                            if (f.elements[i].type == "file") {
                                if (f.elements[i].value == "") {
                                    var elem = f.elements[i];
                                    d.delete(elem.name);
                                }
                            }
                        }
                    }

                    console.log("Action :" + a);

                    if (!a) {
                        console.log("No action - Exiting...");
                        return;
                    }

                    // handle posting of data
                    axios
                        .post(a, d, c)
                        .then((result) => {
                            //access the results here....

                            if (result.status === 200) {
                                var response = result.data;

                                if (response.success && response.finished) {
                                    // Reset the form so that the user may enter fresh information
                                    f.reset();
                                    _s.nextStep();
                                } else if (response.errors || response.formErrors) {
                                    //renderErrors(response.errors, form);
                                    //renderFormErrors(response.formErrors, form);
                                    console.log(response.errors);
                                    console.log(response.formErrors);
                                }

                                if (response.honeypot) {
                                    var honeypotInput = f.querySelector("input[name^=freeform_form_handle_]");
                                    console.log(honeypotInput);
                                    honeypotInput.setAttribute("name", response.honeypot.name);
                                    honeypotInput.setAttribute("id", response.honeypot.name);
                                    honeypotInput.value = response.honeypot.hash;
                                }

                                _s.unlockSubmit(f);
                            } else {
                                console.error(request);
                            }

                            _s.unlockSubmit(f);
                        })
                        .catch(function (error) {
                            // handle error
                            console.log(error);
                            _s.unlockSubmit(f);
                        })
                        .then(function () {
                            // always executed
                        });
                });
            },

            onOptionSelected: function (evt) {
                this.preference = evt.target.getAttribute("data-type");
                this.nextStep();
            },

            nextStep() {
                if (this.step < this.totalSteps) {
                    this.step = this.step + 1;
                }
            },

            lockSubmit(frm) {
                frm.querySelector("button[type=submit]").setAttribute("disabled", "");
            },

            unlockSubmit(frm) {
                frm.querySelector("button[type=submit]").removeAttribute("disabled");
            },
        },

        computed: {
            connectorStepClass: function () {
                return "step--" + this.step;
            },
        },

        updated: function () {
            this.$nextTick(function () {
                this.configForm();
            });
        },

        mounted() {
            console.log("Connector VUE is Ready, ok");
            this.$nextTick(function () {
                this.init();
            });
        },
    });

    const root = connectorVM.mount("#connector");
    return root
};
// Execute async function
connector().then((root) => {
    console.log("Vue Connector Component Mounted");
})
.catch((error) => {
    console.error("Error Vue Connector Component", error);
});;

export default connector;
